.calendly-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 75%;
  height: 76%;
  top: 70px;
  left: 200px;
}

.calendly-modal-content {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
